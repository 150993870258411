.card-bill{
    --ion-card-background: #F5F5F5;
    max-height: 82px;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    transition: max-height 200ms ease-out;
}

.card-bill ion-card-content{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
}
.card-bill .button-action{
    padding: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #E9E9E9;
}

.card-bill.height-grande{
    max-height: 300px;
    transition: max-height 200ms ease-in;
}

.label-status{
    margin: 0 !important;
    padding: 5px 15px;
    border-radius: 15px;
}

.card-pago{
    background-color: #f0ffed !important;
    border: 1px solid #d5edd1;
}

.showmore-pago{
    background-color: #e4f2e1 !important;
    color: #a3cc9b !important;
}

.expirado{
    background-color: #ff7200;
}
.a-vencer{
    background-color: #b10e12;
}
.pago{
    background-color: #2db10e;
}