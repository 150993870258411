.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 5%;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 0.3px solid var(--ion-color-medium);
}
.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}

@media screen and (min-device-width: 768px){
    .login-screen-logo{
        margin-top: 15%;
    }
}