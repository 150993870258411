.navbar {
  background: url('../../images/headerbackground.jpg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0 -170px;
  height: 20%;
  display: flex;
  padding-right: 30px;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 60px;
}

.logo-nav{
  height: 52px;
  background-image: url('../../images/logowhite.png');
  background-size: 100% auto;
  width: 155px;
}

.nomeNav{
  margin: 0;
}

.fotoNav{
  height: 50px;
  width: 50px;
  border-radius: 50px;
  overflow: hidden;
}

.fotoNav img{
  height: 100%;
  width: 100%;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #B02B2F;
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 99;
  top: 0;
  left: -200%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 200ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
  padding-left: 15px;
}

.navbar-toggle {
  background-color: #B02B2F;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar span {
  margin-left: 16px;
}

.header-nav{
  /* min-height: 20%; */
  width: 100%;
  background-color: #951E22;
}

.subconta-info{
  max-height: 0px;
  /* width: 100%; */
  transition: max-height 200ms ease-out;
  overflow: hidden;
}

.button-action-info{
  border-radius: 50%;
  background-color: transparent;
}

.button-action-info:focus{
  outline: none;
}

.btn-troca-subconta{
  width: 90%;
  padding: 10px 10px;
  background-color: transparent;
  color: #FFF;
  border: 1px solid #FFF;
  border-radius: 20px;
  transition: 200ms;
}

.btn-troca-subconta:hover{
  background-color: #FFF;
  color: var(--ion-color-aster-primary);
  transition: 200ms;
}

.info-opened{
  max-height: 250px;
  transition: max-height 200ms ease-in;
}

@media screen and (min-device-width: 1024px){
  .navbar {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
}