.label-float{
  margin-bottom: 2px;
  width: 100%;
  display: grid;
}

.login-page .label-float{
  background-color: #FFFFFF !important;
}

.icon-area{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  grid-column: 1/1;
}

.input-area{
  position: relative;
  height: 100%;
  width: 100%;
  grid-column: 2/2;
}
  
.label-float input{
  color: #000;
  border: none;
  border-radius: 3px;
  outline: none;
  width: 100%;
  padding: 25px 20px;
  font-size: 16px;
  transition: all .1s linear;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -webkit-appearance:none;
  letter-spacing: 1px;
}

/* .label-float input:focus{
  border: 1px solid var(--ion-color-h3);
} */

.label-float input::placeholder{
  color:transparent;
}

.label-float label{
  color: var(--ion-color-h3);
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 20px;
  transition: all .1s linear;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  /* background-color: white; */
  /* padding: 5px; */
  box-sizing: border-box;
}

.label-float input:required:invalid + label{
  color: red;
}
.label-float input:focus:required:invalid{
  border: 2px solid red;
}
.label-float input:required:invalid + label:before{
  content: '*';
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label{
  font-size: 13px;
  top: calc(25% - 8px);
  color: var(--ion-color-h3);
}

.icone{
  color: var(--ion-color-h3);
  font-size: 20px;
}

.no-background{
  background-color: transparent;
  border-bottom: 1px solid var(--ion-color-medium);
}

.no-background .input-area,.icon-area, input{
  background-color: transparent;
}

@media screen and (min-device-width: 1024px){
  .label-float{
    border-bottom: 1px solid #ccc;
  }
}
