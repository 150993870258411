.abertura-chamados .navbar{
    background: url('../images/header-background-white.jpg');
    background-position: 0 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    border-radius: 0;
    height: 25%;
    align-items: flex-start;
    padding-top: 30px;
}

.abertura-chamados ion-content{
    --ion-background-color: linear-gradient(180deg, rgba(243,243,243,1) 18%, rgba(255,255,255,1) 35%);
}

.abertura-chamados .navbar .logo-nav{
    height: 52px;
    background-image: url('../images/logored.png');
    background-size: 100% auto;
    width: 200px;
  }

.abertura-chamados .navbar .menu-bars svg{
    fill: var(--ion-color-aster-primary) !important;
}