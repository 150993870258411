.card-doc{
    --ion-card-background: #F5F5F5;
    box-shadow: none !important;
    margin-bottom: 0 !important;
}

.card-doc ion-card-content{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
}
.card-doc .button-action{
    padding: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: transparent;
}

.label-tipo{
    margin: 0 !important;
    padding: 5px 15px;
    border-radius: 20px;
    display: inline-block;
    background-color: violet;
}

.boleto-f{
    background-color: #b10e12;
}
.nota-fiscal-f{
    background-color: #ff7200;
}
.recibo-de-locação-f{
    background-color: #0f39d1;
}
.protocolo-de-conectividade{
    background-color: #32aaf0;
}
.comprovantes-de-pagamento{
    background-color: #2db10e;
}
.contrato{
    background-color: #c42fed;
}
.outros{
    background-color: #ede32f;
}

.checkbox-container {
	display: block;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  
  .checkbox-container span{
	margin: 0;
  }
  /* Hide the browser's default checkbox */
  .checkbox-container input {
	position: relative;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
	position: absolute;
	top: 25%;
	left: 25%;
	height: 25px;
	width: 25px;
	border-radius: 5px;
  }
  
  .checkbox-container input ~ .checkmark{
	  background-color: #e3e3e3;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
	background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
	background-color: var(--ion-color-aster-primary);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
	display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
  }
  