.btn-form{
    width: 100%;
    height: 60px;
    padding: 15px 20px;
    background: linear-gradient(164deg, rgba(177,14,18,1) 18%, rgba(189,50,54,1) 56%);
    color: #FFF;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 10px;
    box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.43);
}