.container-doc-card{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.container-doc-card li{
    list-style: none;
    flex: 0 0 100%;
    height: auto;
}

.overlay{
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-modal-filtro{
    height: 120px;
    width: 100%;
    background: url('../images/headerbackground.jpg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 -180px;
    display: flex;
    align-items: center;
    padding: 0 25px !important;
}

.content-modal-filtro{
    background-color: #FFF !important;
    padding-top: 20px;
    height: calc(100vh - 120px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.datepicker{
    padding: 10px 30px 10px 0px;
    border: 1px solid #CCC;
    background-color: #FFF;
    border-radius: 5px;
}

.picker-button{
    color: var(--ion-color-aster-primary) !important;
    font-family: 'Kelson' !important;
}

.picker-opt-selected{
    color: var(--ion-color-aster-primary) !important;
}

.btn-download-docs{
    width: 100%;
    padding: 20px 10px;
    background: linear-gradient(34deg, rgba(214,20,25,1) 28%, rgba(140,6,7,1) 78%);
    border-radius: 10px;
    display: none;
}
.btn-download-docs.show{
    display: block;
}

.doc-viewer{
    width: 100% !important;
    height: auto !important;
}
.doc-page{
    width: 100% !important;
    /* height: auto !important; */
}

.doc-page canvas{
    width: 100% !important;
    height: auto !important;
}

.react-pdf__Page__annotations{
    display: none;
}

@media screen and (min-device-width: 1024px){
    .doc-card{
        margin-bottom: 24px !important;
    }
    .container-doc-card li{
        flex: 0 0 33.333333%;
    }
}