ion-content {
    background-color: blue !important;
    --keyboard-offset: 0px !important;
}

.common-pages ion-content {
    --ion-background-color: #FFF;
}

.login-screen-logo {
    margin-top: 15%;
    margin-bottom: 10%;
    width: 100%;
}

.login-screen-logo img {
    width: 100%;
}

.btn-select-subconta {
    width: 100%;
    padding: 20px 10px;
    border: 2px solid #ccc;
    background-color: transparent;
    border-radius: 20px;
    transition: 200ms;
}

.btn-select-subconta:hover {
    background-color: var(--ion-color-aster-primary);
    border-color: var(--ion-color-aster-primary);
    color: #FFF;
    transition: 200ms;
}

.btn-select-subconta:focus {
    outline: none;
}

h4 {
    color: var(--ion-color-h3);
    font-weight: 500;
}

.bold {
    font-weight: bold;
}

.primary-font {
    font-family: 'Kelson';
    letter-spacing: 1px;
}

.primary-regular {
    font-family: 'KelsonMedium';
    letter-spacing: 1px;
}

.primary-bold {
    font-family: 'KelsonBold';
    letter-spacing: 1px;
}

.primary-thin {
    font-family: 'KelsonThin';
    letter-spacing: 1px;
}

.primary-light {
    font-family: 'KelsonLight';
    letter-spacing: 1px;
}

.text-black {
    color: var(--ion-color-aster-black);
}

.text-aster-red {
    color: var(--ion-color-aster-primary);
}

.text-aster-red-dark {
    color: var(--ion-color-aster-red-dark);
}

.text-gray {
    color: var(--ion-color-medium);
}

.text-white {
    color: var(--ion-color-white);
}

.text-green {
    color: var(--ion-color-green);
}

.font-small {
    font-size: 14px !important;
}

.font-extra-small {
    font-size: 11px !important;
}

.font-medium {
    font-size: 1rem !important;
}

.font-big {
    font-size: 1.5rem !important;
}

.font-extra-big {
    font-size: 2rem !important;
}

.font-1-1 {
    font-size: 1.1rem !important;
}

.font-1-2 {
    font-size: 1.2rem !important;
}

.font-1-3 {
    font-size: 1.3rem !important;
}

.font-1-4 {
    font-size: 1.4rem !important;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-left {
    justify-content: left;
}

.justify-right {
    justify-content: right;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.mt-1 {
    margin-top: 10%;
}

.termos-text {
    max-height: 350px;
    overflow: auto;
}

.page-title-container {
    display: flex;
    justify-content: flex-end;
    margin-top: -30px;
}

.page-title {
    padding: 25px;
    background-color: #FFF;
    box-shadow: 0px 5px 13px -5px rgba(0, 0, 0, 0.36);
    width: 90%;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.page-title span {
    line-height: 1.5rem;
}

.spin {
    animation: spin-animation 800ms infinite linear
}

.btn-modal {
    border: none;
    padding: 15px 0px;
    width: 150px;
    border-radius: 30px;
    margin-top: 10px;
}

.btn-modal:focus {
    outline: none;
}

.btn-modal.cancelar {
    color: #808080;
    background-color: #f2f2f2;
}

.btn-modal.action {
    color: #FFF;
    background-color: #b10e12;
}

.loading-login {
    height: 100vh;
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 100;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    animation: fadein 800ms !important;
    -moz-animation: fadein 800ms !important; /* Firefox */
    -webkit-animation: fadein 800ms !important; /* Safari and Chrome */
    -o-animation: fadein 800ms !important; /* Opera */
}

.pointer {
    cursor: pointer;
}

.errors {
    font-size: 14px;
    display: inline-block;
    margin: 0px;
    margin-top: -10px;
    font-style: italic;
}

.errors ul {
    list-style-type: none;
    padding-left: 0px;
    margin: 0px 0 10px 0;
}


.label-opt {
    padding: 10px;
    color: var(--ion-color-aster-primary);
    background-color: #EDEDED;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
}

.label-opt.active {
    background-color: #4D4D4D;
    color: #FFF;
}

.box-white {
    padding: 0;
    background-color: transparent;
    width: auto !important;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein { /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein { /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.page-title .button-action {
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #E9E9E9;
}


@media screen and (max-device-width: 375px) {
    .title-text {
        font-size: 1rem !important;
    }
}

@media screen and (min-device-width: 1024px) {

    .termos-text::-webkit-scrollbar {
        background-color: rgba(214, 214, 214, 0.6);
        width: 10px;
    }

    .termos-text::-webkit-scrollbar-button {
        display: none;
    }

    .termos-text::-webkit-scrollbar-thumb {
        background-color: rgba(201, 201, 201, 0.5);
    }

    .termos-text::-webkit-scrollbar-thumb:hover {
        background-color: rgba(150, 150, 150, 1);
    }

    .box-white {
        background-color: #FFF;
        padding: 40px;
    }

    .page-title-container {
        display: flex;
        justify-content: center;
        margin-top: -30px;
    }

    .page-title {
        padding-right: 5%;
        padding-left: 5%;
        border-radius: 5px;
    }
}

@media screen and (min-device-width: 1440px) {
    .page-title {
        width: 65%;
    }
}

.swiper-pagination {
    margin-bottom: -10px;
}

.swiper-pagination-bullet-active {
    background: var(--ion-color-aster-primary) !important;
}

.slide-arrow {
    position: absolute;
    bottom: 50%;
    cursor: pointer;
    color: var(--ion-color-aster-primary);
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.slide-arrow.prev {
    left: -20px;
}

.slide-arrow.next {
    right: -20px;
}

.table-border tr td, .table-border tr th {
    border: 1px solid black;
    padding: 10px;
}
