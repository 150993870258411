.btn-arrow{
    width: 100%;
    height: 60px;
    padding: 0;
    color: #FFF;
    font-size: 1rem;
    font-weight: 700;
    display: grid;
    box-shadow: none;
    background: linear-gradient(34deg, rgba(214,20,25,1) 28%, rgba(140,6,7,1) 78%);
}

.btn-arrow .icone-opt{
    width: 100%;
    height: 100%;
    grid-column: 2/2;
    background: transparent;
    font-size: 1.8rem;
    padding: 15px 0px;
}

.btn-arrow .text-button{
    height: 100%;
    width: 100%;
    padding: 20px 0px;
    grid-column: 1/1;
}
