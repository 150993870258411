.person-card{
    --ion-card-background: #F5F5F5;
    box-shadow: none !important;
    margin-bottom: 0 !important;
}

.person-card ion-card-content{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
}
.person-card .button-action{
    padding: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #E9E9E9;
}