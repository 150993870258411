@font-face {
    font-family: 'Kelson';
    src: url('../fonts/Kelson.otf');
}
@font-face {
    font-family: 'KelsonLight';
    src: url('../fonts/Kelson-Light.otf');
}
@font-face {
    font-family: 'KelsonBold';
    src: url('../fonts/Kelson-Bold.otf');
}
@font-face {
    font-family: 'KelsonMedium';
    src: url('../fonts/Kelson-Medium.otf');
}
@font-face {
    font-family: 'KelsonThin';
    src: url('../fonts/Kelson-Thin.otf');
}
@font-face {
    font-family: 'KelsonExtraBold';
    src: url('../fonts/Kelson-ExtraBold.otf');
}