.cadastro .container{
    width: 100%;
    overflow: hidden;
}

.container-maior{
    width: 200%;
    transition: 500ms;
}

.parte-2-ativo{
    margin-left: -100%;
    transition: 500ms;
}

.cadastro-parte-1{
    float: left;
    width: 50%;
}

.cadastro-parte-2{
    float: left;
    width: 50%;
}

.logo-cadastro{
    display: none;
}

.top-text{
    text-align: left;
}

@media screen and (min-device-width: 1024px){
    .top-text{
        text-align: center;
    }
    .logo-cadastro{
        display: block;
    }
}