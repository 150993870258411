.modal-container{
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 100;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
}

.modal-content{
    width: 100%;
    padding: 20px 20px 40px 20px;
    background-color: #FFF;
    border-radius: 10px;
}