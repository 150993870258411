.perfil .navbar{
    background: url('../images/header-background-white.jpg');
    background-position: 0 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    border-radius: 0;
    height: 190px;
    align-items: flex-start;
    padding-top: 30px;
}

.perfil ion-content{
    --ion-background-color: linear-gradient(180deg, rgba(243,243,243,1) 18%, rgba(255,255,255,1) 35%);
}

.perfil .navbar .logo-nav{
    background-image: url('../images/logored.png');
    background-size: 100% auto;
    /* height: 40px !important;
    width: 153px !important; */
}


.perfil .perfil-image{
    height: 50px;
    width: 50px;
    overflow: hidden;
    border-radius: 50px;
    margin-bottom: 10px;
    margin-top: -100px;
}

.perfil .perfil-image img{
    height: 100%;
    width: 100%;
}

.dados-lista{
    padding: 0px !important;
}

.blockquote{
    margin-top: 15px;
    background-color: #F5F5F5;
    color: #696969;
    padding: 20px 10px;
    border-radius: 10px;
}

.blockquote.red{
    background-color: var(--ion-color-aster-primary);
    color: #FFF;
}

.blockquote.gray{
    background-color: var(--ion-color-medium-shade);
    color: #FFF;
}

.header-modal-edicao{
    height: 120px;
    width: 100%;
    background: url('../images/headerbackground.jpg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 -180px;
    display: flex;
    align-items: center;
    padding: 0 20px !important;
}

.content-modal-edicao{
    padding-top: 20px;
    height: calc(100vh - 120px);
    width: 100%;
    overflow: auto;
}

.dados-lista li{
    list-style: none;
    border-bottom: 1px solid #CCC;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
}

.perfil .navbar .menu-bars svg{
    fill: var(--ion-color-aster-primary) !important;
}