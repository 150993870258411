.btn-sso{
    width: 100%;
    height: 60px;
    padding: 0;
    overflow: hidden;
    border: 1px solid;
    border-radius: 30px;
    color: #FFF;
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    box-shadow: none;
    outline: none;
}

.btn-sso .icone-opt{
    width: 20%;
    height: 100%;
    /* grid-column: 1/1; */
    padding: 20px 0;
    background-color: #FFF;
    font-size: 1.2rem;
}

.btn-sso .text-button{
    height: 100%;
    width: 80%;
    padding: 20px 0px;
    /* grid-column: 2/2; */
}

.facebook{
    background-color: var(--ion-color-facebook-blue);
}

.facebook-text{
    color: var(--ion-color-facebook-blue);
}

.facebook-border{
    border-color: var(--ion-color-facebook-blue);
}
.google{
    background-color: var(--ion-color-google-blue);
}

.google-border{
    border-color: var(--ion-color-google-blue);
}