.container-bill-card{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.container-bill-card li{
    list-style: none;
    flex: 0 0 100%;
    height: auto;
}

@media screen and (min-device-width: 1024px){
    .bill-card{
        margin-bottom: 24px !important;
    }
    .container-bill-card li{
        flex: 0 0 33.333333%;
    }
}