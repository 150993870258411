.text-field-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.text-field-container input{
    padding: 20px;
    border: 1px solid #CCC;
    border-radius: 10px;
}
.text-field-container input:focus{
    outline: none;
}

.text-field-container label{
    margin-bottom: 5px;
}