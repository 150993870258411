.checkboxbasic-container {
    /* display: block; */
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

  /* Hide the browser's default checkbox */
.checkboxbasic-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

  /* Create a custom checkbox */
  .checkboxbasic-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    margin-left: 0;
    background-color: #ccc;
}

  /* On mouse-over, add a grey background color */
.checkboxbasic-container:hover input ~ .checkmark {
    background-color: #ccc;
}

  /* When the checkbox is checked, add a blue background */
.checkboxbasic-container input:checked ~ .checkmark {
    background-color: var(--ion-color-aster-primary);
}

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkboxbasic-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

  /* Show the checkmark when checked */
.checkboxbasic-container input:checked ~ .checkmark:after {
    display: block;
}

  /* Style the checkmark/indicator */
.checkboxbasic-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}