.btn-basic{
    width: 100%;
    height: 60px;
    padding: 15px 20px;
    background: linear-gradient(34deg, rgba(214,20,25,1) 28%, rgba(140,6,7,1) 78%);
    color: #FFF;
    font-size: 1rem;
    font-weight: 700;
    transition: 400ms;
}

.btn-basic-disabled{
    transition: 400ms;
    color: rgba(255,255,255, 0.4);
    background: linear-gradient(34deg, rgba(214,20,25,0.4) 28%, rgba(140,6,7,0.4) 78%);
}